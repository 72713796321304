import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { pluck } from 'rxjs/operators';
export const villageVirtualOfficeLinksQuery = gql`query village_virtual_office_links{
  village_virtual_office_links {
    id
    created_at
    updated_at
    type
    position
    url
    attachment
    en_description
    en_name
    te_description
    te_name
    image
  }
}`;
export type VillageVirtualOfficeLinks = {
  id: string;
  created_at: any;
  updated_at: any;
  type: string;
  position: number
  url: string;
  attachment: any;
  en_description: string;
  en_name: string;
  te_description: string;
  te_name: string;
  image: any;
  [key: string] : any;
}
export type Query = {
  village_virtual_office_links: VillageVirtualOfficeLinks[];
}
@Injectable()
export class VillageVirtualOfficeLinksService {

/* --------------------------------- Fields --------------------------------- */

/* ------------------------------- Constructor ------------------------------ */

  constructor(private apollo: Apollo) { }

/* -------------------- Get Village Virtual Office Links -------------------- */

  getVillageVirtualOfficeLinks(){
    return this.apollo.query<Query>({
      query: villageVirtualOfficeLinksQuery
    }).pipe(pluck('data', 'village_virtual_office_links'));
  }
}
