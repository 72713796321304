import { NgxSpinnerService } from 'ngx-spinner';
import { NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { Component, OnInit} from '@angular/core';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

/* --------------------------------- Fields --------------------------------- */

/* ------------------------------- Constructor ------------------------------ */

  constructor(private router: Router, private spinner : NgxSpinnerService){

  }

/* ---------------------------- Life cycle hooks ---------------------------- */

  ngOnInit(){
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
         this.spinner.show();
      }

      if (event instanceof NavigationEnd) {
        this.spinner.hide();
      }

      if (event instanceof NavigationError) {
        this.spinner.hide();
        console.log(event.error);
      }
  });
  }
}
