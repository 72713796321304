import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { pluck } from 'rxjs/operators';
export const getHomeBannersQuery = gql`query getHomeBanners {
  home_banners(order_by: {position: desc}) {
    id
    created_at
    updated_at
    type
    image
    position
    url
  }
}`;
export type HomeBanner = {
  id: string;
  created_at: any;
  updated_at: any;
  type: any;
  image: any;
  position: any;
  url: any;
  [key: string] : any;
}

export type Query = {
  home_banners: HomeBanner[];
}
@Injectable()
export class HomeBannersService {

/* --------------------------------- Fields --------------------------------- */

/* ------------------------------- Constructor ------------------------------ */

  constructor(private apollo: Apollo) { }

/* ---------------------------- Get Home Banners ---------------------------- */

  getHomeBanners(){
    return this.apollo.query<Query>({
      query: getHomeBannersQuery
    }).pipe(pluck('data','home_banners'))
  }
}
