import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { pluck } from 'rxjs/operators';
export const villageContactSettingsQuery = gql`query village_contact_settings{
  village_contact_settings_by_pk(id:"99c983ac-734b-4c22-a0ae-1aba43ce2c69"){
    id
    created_at
    updated_at
    phone
    secondary_phone
    address
    how_to_reach
    nearby_districts
    elevation_from_sea_level
    tourist_attractions
    resource_map
  }
}`;
export type VillageContactSettings = {
  id: string;
  created_at: any;
  updated_at: any;
  phone: any;
  secondary_phone: any;
  address: any;
  how_to_reach: any;
  nearby_districts: any;
  elevation_from_sea_level: any;
  tourist_attractions: any;
  resource_map: any;
  [key: string] : any;
}
export type Query = {
  village_contact_settings_by_pk: VillageContactSettings;
}
@Injectable()
export class VillageContactSettingsService {


/* --------------------------------- Fields --------------------------------- */

/* ------------------------------- Constructor ------------------------------ */

constructor(private apollo: Apollo) { }

/* ------------------------- Village Contact Settings Query ------------------------ */

  getVillageContactSettings(){
    return this.apollo.query<Query>({
      query: villageContactSettingsQuery
    }).pipe(pluck('data', 'village_contact_settings_by_pk'))
  }
}
