import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { pluck } from 'rxjs/operators';
export const publicPlacesSettingsQuery = gql`query public_places_settings {
  public_places_settings_by_pk(id: "99c983ac-734b-4c22-a0ae-1aba43ce2c69") {
    id
    created_at
    updated_at
    mosques
    parks
    temples
    churches
  }
}`;
export type PublicPlacesSettings = {
  id: string;
  created_at: any;
  updated_at: any;
  mosques: any;
  parks: any;
  temples: any;
  churches: any;
  [key: string] : any;
}
export type Query = {
  public_places_settings_by_pk: PublicPlacesSettings;
}
@Injectable()
export class PublicPlacesService {


/* --------------------------------- Fields --------------------------------- */

/* ------------------------------- Constructor ------------------------------ */

constructor(private apollo: Apollo) { }

/* ------------------------- Governance Settings Query ------------------------ */

  getPublicPlacesSettings(){
    return this.apollo.query<Query>({
      query: publicPlacesSettingsQuery
    }).pipe(pluck('data', 'public_places_settings_by_pk'))
  }
}
