import { MatSnackBarModule } from '@angular/material/snack-bar';
import { HomeBannersService } from './services/home-banners.service';
import { EventsSettingsService } from './services/events-settings.service';
import { ToastService } from './services/toast.service';
import { SEOService } from './services/seo.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GovernanceSettingsService } from './services/governance-settings.service';
import { AboutSettingsService } from './services/about-settings.service';
import { PublicPlacesService } from './services/public-places.service';
import { GovernmentOfficesSettingsService } from './services/government-offices-settings.service';
import { InitiativesSettingsService } from './services/initiatives-settings.service';
import { TransportationFacilitiesSettingsService } from './services/transportation-facilities-settings.service';
import { VillageVirtualOfficeLinksService } from './services/village-virtual-office-links.service';
import { VillageVideoItemsService } from './services/village-video-items.service';
import { VillageContactSettingsService } from './services/village-contact-settings.service';
import { NewsletterService } from './services/newsletter.service';
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MatSnackBarModule
  ],
  providers: [
    VillageVideoItemsService,
    VillageVirtualOfficeLinksService,
    HomeBannersService,
    EventsSettingsService,
    TransportationFacilitiesSettingsService,
    InitiativesSettingsService,
    GovernmentOfficesSettingsService,
    PublicPlacesService,
    AboutSettingsService,
    GovernanceSettingsService,
    SEOService,
    ToastService,
    VillageContactSettingsService,
    NewsletterService
  ],
})
export class CoreModule { }
