import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { pluck } from 'rxjs/operators';
export const aboutVillageSettingsQuery = gql`query about_village_settings{
  about_village_settings_by_pk(id:"99c983ac-734b-4c22-a0ae-1aba43ce2c69"){
    id
    created_at
    updated_at
    profile
    history
    philosophy
    key_differentiators
    vision
    mission
    long_term_objectives
    socio_economic_statistics
    streets
    plantation
    dustbins
    no_plastic_village
    no_gutka_village
  }
}`;
export type AboutVillageSettings = {
  id: string;
  created_at: any;
  updated_at: any;

  profile: any;
  history: any;

  philosophy: any;
  key_differentiators: any;
  vision: any;
  mission: any;
  long_term_objectives: any;
  socio_economic_statistics: any;
  streets: any;
  plantation: any;
  dustbins: any;
  no_plastic_village:any;
  no_gutka_village:any;
  [key: string] : any;
}
export type Query = {
  about_village_settings_by_pk: AboutVillageSettings;
}
@Injectable()
export class AboutSettingsService {


/* --------------------------------- Fields --------------------------------- */

/* ------------------------------- Constructor ------------------------------ */

constructor(private apollo: Apollo) { }

/* ------------------------- Governance Settings Query ------------------------ */

  getAboutVillageSettings(){
    return this.apollo.query<Query>({
      query: aboutVillageSettingsQuery
    }).pipe(pluck('data', 'about_village_settings_by_pk'))
  }
}
