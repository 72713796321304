import {InjectionToken, NgModule} from '@angular/core';
import {APOLLO_OPTIONS} from 'apollo-angular';
import {ApolloClientOptions, ApolloLink, InMemoryCache} from '@apollo/client/core';
import {HttpLink} from 'apollo-angular/http';
import { setContext } from '@apollo/client/link/context';
import { makeStateKey, TransferState } from '@angular/platform-browser';
const APOLLO_CACHE = new InjectionToken<InMemoryCache>('apollo-cache');
const STATE_KEY = makeStateKey<any>('apollo-state');
const uri = 'https://narmetta-dev.hasura.app/v1/graphql'; // <-- add the URL of the GraphQL server here
export function createApollo(httpLink: HttpLink,
    cache: InMemoryCache,
   transferState: TransferState): ApolloClientOptions<any> {
  const isBrowser = transferState.hasKey<any>(STATE_KEY);
  if (isBrowser) {
    const state = transferState.get<any>(STATE_KEY, null);
    cache.restore(state);
  } else {
    transferState.onSerialize(STATE_KEY, () => {
      return cache.extract();
    });
  }

  const basic = setContext((operation, context) => ({
    headers: {
      Accept: 'charset=utf-8'
    }
  }));

  const auth = setContext((operation, context) => {
    return {
      headers: {
        'x-hasura-admin-secret':'Ast@2020',
        // Authorization: `JWT ${token}`
      }
    };
    /*console.log('hello');
    const token = localStorage.getItem('token');

    if (token === null) {
      return {};
    } else {
      console.log("hello");
      return {
        headers: {
          'x-hasura-admin-secret':'ni09FBrU7T4WARQ4aHwQ7qKt2xgLGtH9wofn0sDuzIidDSJukhqxTHswKc6YW1nN',
          // Authorization: `JWT ${token}`
        }
      };
    }*/


  });

  const link = ApolloLink.from([basic, auth, httpLink.create({ uri })]);
  // const cache = new InMemoryCache();

  return {
    link,
    cache,
  };
}

@NgModule({
  providers: [
    {
      provide: APOLLO_CACHE,
      useValue: new InMemoryCache(),
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, APOLLO_CACHE, TransferState],
    },
  ],
})
export class GraphQLModule {}
