import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { pluck } from 'rxjs/operators';


export const initiativesSettingsQuery = gql`query initiatives{
 initiatives_settings_by_pk(id:"99c983ac-734b-4c22-a0ae-1aba43ce2c69"){
  id
  created_at
  updated_at
  various_development_works_status
  temple_development_and_revival
  cooperative_societies
  talent_management_among_kids
  sports_development
  youth_empowerment
  support_for_farming_sector
  students_empowerment
  fit_village
  computer_education
  }
}`;
export type InitiativesSettings = {
  id: string;
  created_at: any;
  updated_at: any;
  various_development_works_status: any;
  temple_development_and_revival: any;
  cooperative_societies: any
  talent_management_among_kids: any;
  sports_development: any;
  youth_empowerment: any
  support_for_farming_sector: any;
  students_empowerment: any;
  fit_village: any;
  computer_education: any;
  [key: string] : any;
}
export type Query = {
  initiatives_settings_by_pk: InitiativesSettings;
}
@Injectable()
export class InitiativesSettingsService {


/* --------------------------------- Fields --------------------------------- */

/* ------------------------------- Constructor ------------------------------ */

constructor(private apollo: Apollo) { }

/* ------------------------- Governance Settings Query ------------------------ */

  getInitiativesSettings(){
    return this.apollo.query<Query>({
      query: initiativesSettingsQuery
    }).pipe(pluck('data', 'initiatives_settings_by_pk'))
  }
}

