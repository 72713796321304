import { pluck } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { gql } from 'apollo-angular';
export const governanceSettingsQuery = gql`query governance_settings{
  governance_settings_by_pk(id:"99c983ac-734b-4c22-a0ae-1aba43ce2c69"){
    id
    created_at
    updated_at
    grampanchayat
    administrative_staff
    co_opted_members
    members_of_panchayat
    constituencies_legislative_assembly
    constituencies_parliamentary
    constituencies
    past_sarpanchs
    strenthening_gram_panchayat
    miniature_bhavan
  }
}`;
export type GovernanceSettings = {
  id: string;
  created_at: any;
  updated_at: any;
  grampanchayat: any;
  administrative_staff: any;
  co_opted_members: any;
  members_of_panchayat: any;
  constituencies_legislative_assembly: any;
  constituencies_parliamentary: any;
  constituencies: any;
  past_sarpanchs: any;
  strenthening_gram_panchayat: any;
  miniature_bhavan: any;
  [key: string] : any;
}
export type Query = {
  governance_settings_by_pk: GovernanceSettings;
}
@Injectable()
export class GovernanceSettingsService {

/* --------------------------------- Fields --------------------------------- */

/* ------------------------------- Constructor ------------------------------ */

  constructor(private apollo: Apollo) { }

/* ------------------------- Governance Settings Query ------------------------ */

  getGovernanceSettings(){
    return this.apollo.query<Query>({
      query: governanceSettingsQuery
    }).pipe(pluck('data', 'governance_settings_by_pk'))
  }
}
