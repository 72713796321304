import { Injectable } from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
const addNewsLetterMutation = gql`mutation addNewsLetterSubscription($object: newsletter_subscribers_insert_input!){
  insert_newsletter_subscribers_one(object: $object){
    id
  }
}`
@Injectable()
export class NewsletterService {

/* ------------------------------- Constructor ------------------------------ */

  constructor(private apollo: Apollo) { }

/* ---------------------- Add News Letter Subscription ---------------------- */

  addNewsLetterSubscription(object: any){
    //const object = {object:emailObj}
    return this.apollo.mutate({
      mutation: addNewsLetterMutation,
      variables: {object}
    })
  }
}
