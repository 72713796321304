import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { pluck } from 'rxjs/operators';
export const villageVideoItemsQuery = gql`query village_videos_items{
  village_videos_items {
    id
    created_at
    updated_at
    position
    type
    media_type
    video
    image
    en_caption
    te_caption
  }
}`;

export const mediaTypeByQuery = gql`query village_videos_items($mediaType: String){
  village_videos_items(where:{media_type: {_eq:$mediaType}}){
    id
    created_at
    updated_at
    position
    type
    media_type
    video
    image
    en_caption
    te_caption
  }
}`;
export type VillageVideoItems = {
  id: string;
  created_at: any;
  updated_at: any;
  position: string;
  type: string;
  media_type: string;
  video:  any;
  image: any;
  en_caption: string;
  te_caption: string;
  [key: string] : any;
}
export type Query = {
  village_videos_items: VillageVideoItems[];
}
@Injectable()
export class VillageVideoItemsService {

/* --------------------------------- Fields --------------------------------- */

/* ------------------------------- Constructor ------------------------------ */

  constructor(private apollo: Apollo) { }

/* -------------------- Get Village Virtual Office Links -------------------- */

  getVillageVideoItems(){
    return this.apollo.query<Query>({
      query: villageVideoItemsQuery
    }).pipe(pluck('data', 'village_videos_items'));
  }

  /* -------------------- Get Village Virtual Office Links -------------------- */

  getMediaByType(mediaType: string){
    return this.apollo.query<Query>({
      query: mediaTypeByQuery,
      variables: {mediaType}
    }).pipe(pluck('data', 'village_videos_items'));
  }
}

