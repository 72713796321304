import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { pluck } from 'rxjs/operators';

export const transportationFacilitiesSettingsQuery = gql`query initiatives{
  transportation_facilities_settings_by_pk(id:"99c983ac-734b-4c22-a0ae-1aba43ce2c69"){
  id
  created_at
  updated_at
  roads
  rtc_connectivity
  private_transport
  autos
  }
}`;
export type TransportationFacilitiesSettings = {
  id: string;
  created_at: any;
  updated_at: any;
  roads: any;
  rtc_connectivity: any;
  private_transport: any;
  autos: any;
  [key: string] : any;
}
export type Query = {
  transportation_facilities_settings_by_pk: TransportationFacilitiesSettings;
}
@Injectable()
export class TransportationFacilitiesSettingsService {


/* --------------------------------- Fields --------------------------------- */

/* ------------------------------- Constructor ------------------------------ */

constructor(private apollo: Apollo) { }

/* ------------------------- Governance Settings Query ------------------------ */

  getTransportationFacilitiesSettings(){
    return this.apollo.query<Query>({
      query: transportationFacilitiesSettingsQuery
    }).pipe(pluck('data', 'transportation_facilities_settings_by_pk'))
  }
}


