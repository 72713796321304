<section class="coming_soon_sec">
  <div class="coming_soon_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row"
    fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="0px">
    <div class="coming_soon_column" fxLayout="column" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50"
      fxFlex.xs="100" fxFlexFill>
      <div class="coming_soon_left">
        <div class="coming_soon_flex">
          <div class="logo">
            <a [routerLink]="['/index']">
              <img src="../../../../../assets/images/logo.png" alt="logo">
            </a>
          </div>
          <div class="coming-soon-title">
            <h3>
              <span>Coming Soon</span></h3>
          </div>
          <!-- <div class="head">
            <h2>Coming Soon</h2>
          </div> -->
        </div>
      </div>
    </div>
    <div class="coming_soon_column" fxLayout="column" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50"
      fxFlex.xs="100" fxFlexFill>
      <div class="coming_soon_right">
        <img src="../../../../../assets/images/cm1.jpg" alt="coming-soon">
      </div>

    </div>
  </div>
</section>
