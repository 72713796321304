import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { pluck } from 'rxjs/operators';
export const eventsSettingsQuery = gql`query events_settings{
  events_settings_by_pk(id:"99c983ac-734b-4c22-a0ae-1aba43ce2c69"){
    id
    created_at
    updated_at
    religious_celebrations
    melas_or_jataras
    drama
  }
}`;
export type EventsSettings = {
  id: string;
  created_at: any;
  updated_at: any;
  religious_celebrations: any;
  melas_or_jataras: any;
  drama: any;
  [key: string] : any;
}
export type Query = {
  events_settings_by_pk: EventsSettings;
}
@Injectable()
export class EventsSettingsService {


/* --------------------------------- Fields --------------------------------- */

/* ------------------------------- Constructor ------------------------------ */

constructor(private apollo: Apollo) { }

/* ------------------------- Governance Settings Query ------------------------ */

  getEventsSettings(){
    return this.apollo.query<Query>({
      query: eventsSettingsQuery
    }).pipe(pluck('data', 'events_settings_by_pk'))
  }
}
