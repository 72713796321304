import { pluck } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { gql } from 'apollo-angular';
export const governmentOfficesSettingsQuery = gql`query government_offices_settings{
  government_offices_settings_by_pk(id:"99c983ac-734b-4c22-a0ae-1aba43ce2c69"){
    id
    created_at
    updated_at
    grampanchayat
    village_exhibition_center
    primary_healthcare_center
    one_zero_four_service
    anganwadi_centers
    schools_and_institutes
    community_water_supplys
    sanitization
    irrigation_and_agriculture
    electricity_transmission
    civil_supplies
    revenue_and_land_survey
    law_and_order
    self_help_groups
    milk_diaries
    ambulance_services
    telecommunications
    postal_services
    animal_husbandry
    community_centers
    hundred_percent_literacy
    hundred_percentage_employment
    medical
    organic_farming_bio_farming
    tsiic
    solar_plant_and_lighting
    lakes_and_canals
    adimanavula_gundu_and_burunu
  }
}`;
export type GovernmentOfficesSettings = {
  id: string;
  created_at: any;
  updated_at: any;
  grampanchayat: any;
  village_exhibition_center: any;
  primary_healthcare_center: any;
  one_zero_four_service: any;
  anganwadi_centers: any;
  schools_and_institutes: any;
  community_water_supplys: any;
  sanitization: any;
  irrigation_and_agriculture: any;
  electricity_transmission: any;
  civil_supplies: any;
  revenue_and_land_survey: any;
  law_and_order: any;
  self_help_groups: any;
  milk_diaries: any;
  ambulance_services: any;
  telecommunications: any;
  postal_services: any;
  animal_husbandry: any;
  community_centers: any;
  hundred_percent_literacy: any;
  hundred_percentage_employment: any;
  medical: any;
  organic_farming_bio_farming: any;
  tsiic: any;
  solar_plant_and_lighting: any;
  lakes_and_canals: any
  adimanavula_gundu_and_burunu: any;
  [key: string] : any;
}
export type Query = {
  government_offices_settings_by_pk: GovernmentOfficesSettings;
}
@Injectable()
export class GovernmentOfficesSettingsService {

/* --------------------------------- Fields --------------------------------- */

/* ------------------------------- Constructor ------------------------------ */

  constructor(private apollo: Apollo) { }

/* ------------------------- Governance Settings Query ------------------------ */

  getGovernmentOfficesSettings(){
    return this.apollo.query<Query>({
      query: governmentOfficesSettingsQuery
    }).pipe(pluck('data', 'government_offices_settings_by_pk'))
  }
}
