import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UnderConstructionComponent } from './modules/home/pages/under-construction/under-construction.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import ('src/app/modules/home/home.module').then ( (m) => m.HomeModule )
  },
  {
    path: 'mobile',
    loadChildren: () => import ('src/app/modules/mobile/mobile.module').then ( (m) => m.MobileModule)
  },
  {
    path: 'coming-soon',
    component: UnderConstructionComponent
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
